
import Vue, { PropType } from "vue";

import { DialogCloseType, DialogI } from "@/types/dialog";
import BaseDialog from "@/components/dialog/BaseDialog.vue";
import Btn from "@/components/v2/base/Btn.vue";
import { validator } from "@/utils/validators";
import TextField from "@/components/form/inputs/TextField.vue";

export default Vue.extend({
  name: "AddFirstAndLastNameToCompanyUser",
  components: {
    TextField,
    BaseDialog,
    Btn,
  },

  data() {
    return {
      valid: false,
      firstname: "",
      lastname: "",
    };
  },

  mixins: [validator],

  methods: {
    async submit() {
      await this.validate();
      if (!this.valid) {
        return;
      }
      this.dialog.close({
        type: DialogCloseType.SUBMITTED,
        payload: {
          firstname: this.firstname,
          lastname: this.lastname,
        },
      });
    },
  },

  props: {
    dialog: {
      type: Object as PropType<DialogI>,
      required: true,
    },
  },
});
